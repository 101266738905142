import React from 'react';
import '../../App.css';
import AboutInfo from '../AboutInfo.js';
import Footer from '../Footer';

function About() {
  const awardsIcons = (
    <div className='awards-container'>
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
    <img
        src='/images/pieredze.png'
        alt='Pieredze'
        width='100'
        height='100'
        style={{ margin: '10px', maxWidth: '50%', maxHeight: '100px' }}
      />
      <img
        src='/images/biedrs.png'
        alt='Biedrs'
        width='150'
        height='100'
        style={{ margin: '10px', maxWidth: '50%', maxHeight: '100px' }}
      />
      <img
        src='/images/sandals.png'
        alt='Sandals'
        width='190'
        height='80'
        style={{ margin: '10px', maxWidth: '100%', maxHeight: '100px' }}
      />
      <img
        src='/images/sandalsb.png'
        alt='Sandals'
        width='190'
        height='80'
        style={{ margin: '10px', maxWidth: '100%', maxHeight: '100px' }}
      />
    </div>
    </div>
  );
  
  const customRightContent = (
    <div className="dual-container">
  <div className="custom-right-content-inner">
    <div className="custom-left">
      <img src='/images/Gunta-Zirne-VIP-travel.jpg' alt="Gunta-bilde" className="custom-person-image" />
    </div>
    <div className="custom-right">
      <h2>Gunta Zirne</h2>
      <p>Uzņēmuma vadītāja ar milzīgu pieredzi tūrisma industrijā. </p>
    </div>
  </div>
  
  <div className="custom-right-content-inner-right">
    <div className="custom-left">
      <img src='/images/Lote-VIP-travel.jpg' alt="Lote-bilde" className="custom-person-image" />
    </div>
    <div className="custom-right">
      <h2>Lote Annija Ankalniņa</h2>
      <p>Tūrisma eksperte ar lieliem mērķiem un motivāciju tiekties pēc jaunām iespējām un pieredzēm.</p>
    </div>
  </div>
</div>

  );
  return (
    <>
      <AboutInfo />
      {customRightContent}
      {awardsIcons}
      <Footer />
    </>
  );
}

export default About;