import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  const phoneNumber = '+37129419903';
  return (
    <React.Fragment>
      <div className='footer-container'>
        <section className='footer-subscription'>
          <p className='footer-subscription-heading'>
            Mēs palīdzēsim radīt Jūsu ideālās brīvdienas!
          </p>
        </section>
        <section className='social-media'>
          <div className='social-media-wrap'>
            <div className='footer-logo'>
              <Link to='/' className='social-logo'>
                <img src="/images/logowhite.png" alt='VIP Travel logo' />
              </Link>
            </div>
            <small className='address-small'>
              Daugavas iela 38-3, Mārupe, Mārupes novads, LV-2167
            </small>
            <div className='social-icons'>
              <Link
                className='social-icon-link facebook'
                to='https://www.facebook.com/viptravel.lv/'
                target='_blank'
                aria-label='Facebook'
              >
                <i className='fab fa-facebook-f' />
              </Link>
              <Link
                className='social-icon-link instagram'
                to='https://www.instagram.com/viptravel.latvia/'
                target='_blank'
                aria-label='Instagram'
              >
                <i className='fab fa-instagram' />
              </Link>
              <Link
        className='social-icon-link whatsapp'
        to={`https://wa.me/${phoneNumber}`}
        target='_blank'
        aria-label='Whatsapp'
      >
        <i className='fab fa-whatsapp' />
      </Link>
            </div>
          </div>
        </section>
        <small className='website-rights'>VIP TRAVEL © 2023</small>
      </div>
    </React.Fragment>
  );
}

export default Footer;
