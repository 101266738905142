import React from 'react';
import '../../App.css';
import ContactForm from '../ContactForm';
import Footer from '../Footer';
import '../Contacts.css';
import { Link } from 'react-router-dom';

function Contacts() {
  const phoneNumber = '+37129419903';
  const customRightContent = (
    <>
      <div className="smallContainer">
        <div className="mapContainer">
          <iframe
            src="https://www.google.com/maps/embed?pb=!3m2!1slv!2slv!4v1691596509834!5m2!1slv!2slv!6m8!1m7!1sr7IyPdlzzXLGg8JOVyd7Cw!2m2!1d56.89843641424091!2d24.03583590878607!3f281.3126301779995!4f10.03655201909963!5f1.8357665673518402"
            width="100%"
            height="0"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="mapsFrame"
          ></iframe>
        </div>
        <div className="infoContainer">
          <ul>
            <li><i className="fas fa-map-marker-alt"></i>Daugavas iela 38-3, Mārupe</li>
            <li><i className="fa fa-phone"></i><a href="tel:+37129419903">+371 29419903</a></li>
            <li><i className="fa fa-envelope"></i><a href="mailto:info@viptravel.lv">info@viptravel.lv</a></li>
            <div className='social-icons-contacts'>
              <Link
                className='social-icon-link facebook'
                to='https://www.facebook.com/viptravel.lv/'
                target='_blank'
                aria-label='Facebook'
                style={{ color: 'black' }}
              >
                <i className='fab fa-facebook-f' />
              </Link>
              <Link
                className='social-icon-link instagram'
                to='https://www.instagram.com/viptravel.latvia/'
                target='_blank'
                aria-label='Instagram'
                style={{ color: 'black' }}
              >
                <i className='fab fa-instagram' />
              </Link>
              <Link
        className='social-icon-link whatsapp'
        to={`https://wa.me/${phoneNumber}`}
        target='_blank'
        aria-label='Whatsapp'
        style={{ color: 'black' }}
      >
        <i className='fab fa-whatsapp' />
      </Link>
            </div>
          </ul>
        </div>
      </div><br/>

      <div className="smallContainer">
        <div className="infoContainer-bottom">
        <h4>Uzņēmuma rekvizīti:</h4>
          <p>SIA "V.I.P. Travel Agency"<br/>
Ceļojumu organizētāja reģistrācijas Nr.PTAC T-2019-43</p><br/>
<p>Reģ. Nr. LV 40103162461<br/>Banka:
A/S „SWEDBANK” <br/> Norēķinu konts:</p><h4 style={{fontSize: 16}}>LV41HABA0551021223849</h4>
        </div>
      </div>
    </>
  );
  

  return (
    <>
    <div className='contacts-container'>
      <ContactForm rightContent={customRightContent} />
      </div>
      <Footer />
    </>

  );
}

export default Contacts;
