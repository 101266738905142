import React, { useEffect } from 'react';
import './App.css';
import Navbar from './Components/Navbar'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/pages/Home';
import Services from './Components/pages/Services';
import About from './Components/pages/About';
import Contacts from './Components/pages/Contacts';
import Charter from './Components/pages/Charter';
import Nosutits from './Components/pages/Nosutits';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/pakalpojumi' element={<Services />} />
          <Route path='/parmums' element={<About />} />
          <Route path='/kontakti' element={<Contacts />} />
          <Route path='/charter' element={<Charter />} />
          <Route path='/nosutits' element={<Nosutits />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
