import React, { useState } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {
  const [videoError, setVideoError] = useState(false);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <div className='hero-container'>
      <div className='video-container'>
        {videoError ? (
          <img
            src='/images/backup-image.jpg'
            alt='Default'
            className='default-image'
          />
        ) : (
          <video
            src='/videos/video-1.mp4'
            autoPlay
            loop
            muted
            playsInline
            onError={handleVideoError}
            className='video-element'
            loading="lazy"
          />
        )}
      </div>
      <h2>ATKLĀSIM PASAULI KOPĀ</h2>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          to='/pakalpojumi'
        >
          PIEDĀVĀJUMI
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          to='/kontakti'
        >
          Kontakti <i className='fa-regular fa-address-card' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
