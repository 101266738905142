import React from 'react';
import './ContactForm.css';

const ContactForm = ({ rightContent }) => {
  return (
    <div className='sekcijacontainer'>
      <section className="sekcija1">
        <div className="left-side">
        <h3>Uzraksti mums!</h3>
          <form action="https://formsubmit.co/info@viptravel.lv" method="POST">
          <input type='text' name='_honey' style={{ display: 'none' }}/>
          <input type='hidden' name='_captcha' value='false'/>
          <input type='hidden' name='_next' value='https://viptravel.lv/nosutits'/>
          <input type="hidden" name="_subject" value="VIP New request"></input>
            <div className="form-group">
              <label htmlFor="name"></label>
              <input type="text" id="name" name="Vards" placeholder="Vārds Uzvārds" required />
              <label htmlFor="phone"></label>
              <input type="tel" id="phone" name="Telefons" placeholder="Telefona nr." required />
            </div>
            <input type="email" id="email" name="Epasts" placeholder="E-pasts" required />
            <label htmlFor="message"></label>
            <textarea id="message" name="Zinojums" rows="4" placeholder="Rakstiet savu ziņojumu šeit"></textarea>
            <input type="submit" value="Nosūtīt ziņojumu" />
          </form>
        </div>
        <div className="right-side">
          {rightContent}
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
