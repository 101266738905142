import React from 'react';
import './AboutInfo.css';

const AboutUs = () => {
  return (
    <div className="container-box">
    <div className="container">
      <div className="left">
        <h1>Par V.I.P Travel</h1>
        <strong>Tūrisma aģentūra „V.I.P. Travel Agency” ir dibināta 2008. gada aprīlī.</strong><br></br>
        <p>
        Mūsu vīzija – padarīt katra mūsu klienta sapni par īstenību. Mūsu mērķis – pakalpojumu kvalitāte, perfekta cena un apkalpošana visaugstākā līmenī! Mēs to apstiprināsim tāpat kā jebkuru ceļojumu, ko izvēlēsieties Jūs!<br></br>
Mūsu darbinieku īpašās zināšanas, pieredze tūrisma industrijā vairāk nekā 20 gadu garumā, pakalpojumu sniegšanas kvalitāte, un personiskā attieksme norāda, ka sniegtais pakalpojums ir savā ziņā unikāls, jo mēs spējam sniegt to, ko klientam nespēj piedāvāt internets!
        </p>
      </div>
      <div className="right">
        <img src="/images/aboutimg.jpg" alt="Bildeparmums" />
      </div>
    </div>
    </div>
  );
};

export default AboutUs;