import React from 'react';
import '../../App.css';
import '../Nosutits.css';
import Footer from '../Footer';

function Nosutits() {
    return (
      <div className="message-sent-container">
        <div className="message-sent-content">
          <h2>Ziņa nosūtīta veiksmīgi</h2>
          <p>Jūsu ziņa ir nosūtīta un tiks izskatīta tuvākajā laikā!</p>
          <a href="/" style={{ marginTop: '20px' }}>
          <button
          style={{
            backgroundColor: 'black',
            color: 'white',
            marginTop: '20px',
            border: 'none',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
        >
          Atpakaļ uz sākumu
        </button></a>
        </div>
        
        <Footer />
      </div>
    );
  }
  

export default Nosutits;