import React from 'react';
import '../../App.css';
import '../Charter.css';
import Footer from '../Footer';

const countryList = [
  "Akaba", "Antālija", "Bali", "Bangkoga", "Barselona",
  "Batumi", "Bergamo", "Burga", "Dubaija", "Enfida",
  "Havana", "Hošimina", "Hurgada", "Kankūna", "Katānija",
  "Kolombo", "Korfu", "Krēta", "Larnaka", "Madeira",
  "Male", "Maurīcija", "Peleponēsa", "Puketa", "Punta Cana",
  "Rimini", "Roda", "Sardīnija", "Seišelu salas", "Šarm el Šeiha",
  "Tenerife", "Tivata", "Zalcburga", "Zanzibāra", "Ženēva"
];

function Charter() {
  return (
    <>
<div className="Charter-container">
      <header className="Charter-list">
        <h1>Čarteru galamērķi</h1>
        <ul className="Country-list">
          {countryList.map((country, index) => (
            <li key={index}>{country}</li>
          ))}
        </ul>
      </header>
    </div>
    <Footer />
    </>
  );
}

export default Charter;