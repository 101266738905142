import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import ContactForm from '../ContactForm';
import Footer from '../Footer';

function Home() {
  const customRightContent = (
    <div className="rteksts">
              <h2>Jauni piedzīvojumi gaida tevi!</h2>
              <p>Dodaties ceļojumā uz neaizmirstamiem galamērķiem ar mūsu ekspertu veidotām ceļojumu pieredzēm. Sazinaties ar mums jau tagad, lai sāktu plānot savu sapņu atvaļinājumu!</p>
              <a id="learnmore" href="https://www.viptravel.lv/kontakti">Uzzini vairāk</a>
            </div>)
  return (
    <>
      <HeroSection />
      <ContactForm rightContent={customRightContent}/>
      <Cards />
      <Footer />
    </>
  );
}

export default Home;